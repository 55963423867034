<script>
import VideoAnnotationNewView from './VideoAnnotationNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'VideoAnnotationEditView',
  extends: VideoAnnotationNewView,
  methods: {
    save (redirect = 'edit') {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.dataLoaded = false
      this.$store.dispatch('videoAnnotation/update', this.prepareRequest(this.annotation))
        .then(() => {
          this.getVideo()
          NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          if (redirect === 'new') {
            this.$router.push('/videoOld/' + this.annotation.videoId + '/annotation/new')
          }
          if (redirect === 'close') {
            this.$router.push('/videoOld/' + this.annotation.videoId + '/edit')
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
        })
    }
  },
  created () {
    this.getAnnotation()
  }
}
</script>
